import { useEffect, useRef } from 'react';
import FormControl from 'react-bootstrap/esm/FormControl';


export default function InventorySearch({onSearch, search, disableAutoFocus}) {
    const searchRef = useRef(null);
    useEffect(_ => {
        function listener(event) {
            console.log(event)
            if (event.ctrlKey || event.altKey || event.metaKey) return;
            if (searchRef.current !== document.activeElement) onSearch('')
            searchRef.current?.focus()
        }
        if (!disableAutoFocus) {
            document.addEventListener('keydown', listener);
            var listenerAdded = true;
        }
        return _ => listenerAdded && document.removeEventListener('keydown', listener);
    }, [onSearch, disableAutoFocus])
    return <FormControl value={search} onChange={e => onSearch(e.target.value || '')} ref={searchRef} placeholder='search' className='mt-0 pt-0'/>
}
