import React, { useState, useEffect } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';
import axios from 'axios';
import Loading from './loading';
import OrderItemsTable from './orderItemsTable';
import Button from 'react-bootstrap/esm/Button';
import OrderPricingSection from './orderPricingSection';

let useBlazeStage = !!window.origin.split('smart-dispensary')[0]?.split('//')[1];

export default function OrderDetails(props) {

    const [order, setOrder] = useState();

    useEffect(() => {
        (async () => {
            const result = await axios.get(`/order/${props.match.params.orderId}`);
            setOrder(result.data);
        })();
    }, [props.match.params.orderId]);

    if (!order) return <Loading/>;
    return (
        <Container>
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item href={`#/orders?date=${DateTime.fromISO(order.timestamp).toISODate()}`}>Order Log</Breadcrumb.Item>
                <Breadcrumb.Item active>{props.match.params.orderId}</Breadcrumb.Item>
            </Breadcrumb>
            <Container>
            <Row className='mt-0 mb-2 text-left' >
              <Col className='pt-0'>
                <Row className='mt-2'>
                  <Col className='pt-0 pr-4'>
                    <p className='mb-0 pr-2 font-weight-bold'>{(order.customer.name)}</p>
                    <p className='mb-0'>{order.customer.email}</p>
                    <p className='mb-0'>{order.customer.cell}</p>
                    {order.preorder && <p className='text-success font-italic'>preorder</p>}
                  </Col>
                  <Col className="pt-0 pr-4 text-right">
                    <p className='mb-0 font-weight-bold'>{DateTime.fromISO(order.timestamp).toLocaleString(DateTime.DATETIME_FULL)} @ {order.createdTerminalId ? `Side ${order.createdTerminalId.split('-t')[1]}` : 'Unknown'}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <OrderItemsTable order={order} />
            <Row className='mt-2 ml-1' >
              <Col className='pt-0 text-left text-info' >
                {!Object.values(order.sidesVended || {}).reduce((c,x) => c && x, true) && <>*Some items in this order have not been picked up yet.</>}
                {!!order.blazeOrderId && <Button className='mt-2' variant='outline-info' href={`https://retail${useBlazeStage ? '.staging' : ''}.blaze.me/transactions/${order.blazeOrderId}`} target='_blank' rel="noreferrer noopener">View in Blaze</Button>}
              </Col>
              <Col className='pt-0' lg={4}>
                <OrderPricingSection order={order}/>
              </Col>
            </Row>
          </Container>
        </Container>
    )
}