import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import useShop from '../../hooks/useShop';
import Loading from '../loading';
import ShopInfoSettings from './shopInfoSettings';
import EmloyeeSettings from './emloyeeSettings';
import TaxSettings from './taxSettings';
import OnlineStoreSettings from './onlineStoreSettings';
import IntegrationSettings from './integrationSettings';
import NotificationSettings from './notificationSettings';
import PickupCounterSettings from './pickupCounterSettings';

export default function Settings({ permissions }) {

    const [currentShopSettings, refreshCurrentShopSettings] = useShop();


    if (!permissions || !currentShopSettings) return <Loading />
    if (!permissions.some(per => per === 'admin')) return <h1 style={{ marginTop: '30px', }}>You not have access to settings</h1>

    return (
        <div style={{ position: 'relative' }} className='mt-3 mx-2'>
            <Tabs defaultActiveKey="shop" id="uncontrolled-tab-shop-settings">
                <Tab eventKey="shop" title="Shop">
                    <ShopInfoSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                </Tab>
                <Tab eventKey="emloyees" title="Employees">
                    <EmloyeeSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                </Tab>
                { (!currentShopSettings.inventoryManagement || currentShopSettings.inventoryManagement === 'greenstop') &&
                    <Tab eventKey="taxSettings" title="Taxes" disabled>
                        <TaxSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                    </Tab>
                }
                <Tab eventKey="notifications" title="Notifications">
                    <NotificationSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                </Tab>
                <Tab eventKey='onlineStoreSettings' title='Online Store'>
                    <OnlineStoreSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                </Tab>
                <Tab eventKey='integration-settings' title='Integration'>
                    <IntegrationSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                </Tab>
                <Tab eventKey="counterPickupSettings" title="Counter Pickup">
                    <PickupCounterSettings currentShopSettings={currentShopSettings} refreshCurrentShopSettings={refreshCurrentShopSettings}/>
                </Tab>
            </Tabs>
        </div>
    )
}
