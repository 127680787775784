import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import InventoryTable from './inventoryTable';
import EditProductModal from '../modals/editProductModal';
import AssignPackageModal from '../modals/assignPackageModal';
import styled from 'styled-components';
import useCategoryMap from '../../hooks/useCategoryMap';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import InventorySearch from './inventorySearch';
import QuickTransferModal from './quickTransferModal';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';

const ALL_INVENTORY_URL = '/inventory/all';
const KIOSK_INVENTORY_URL = '/inventory/kiosk';
const BACKSTOCK_URL = '/inventory/backstock';
const PICKUP_COUNTER_URL = '/inventory/pickupCounter';


const INVENTORY_URL_MAP = {
    totalInventory: ALL_INVENTORY_URL,
    pickup: PICKUP_COUNTER_URL,
    kioskInventory: KIOSK_INVENTORY_URL,
    backstock: BACKSTOCK_URL
}

const InventoryContainer = styled.div`
    .btn {
        float: right;
        padding: 10px;
        background: #FFFFFF;
        border: 1px solid #26A65B;
        color: #26A65B;
        margin-top: 7px;
        margin-right: 7px;
    }
    .btn:hover {
        background: #26A65B;
        color: #FFFFFF;
    }
    @media(max-width: 500px) {
        .btn {
            float: none;
        }

    }
`;

const generateSearchResult = (product, searchStr, matchedProductId) => {
    product['searchIndex'] = 100 * product.name.toUpperCase().search(searchStr.toUpperCase());
    if (product.sku && product['searchIndex'] < 0) {
        product['searchIndex'] = 10 * product.sku.toUpperCase().search(searchStr.toUpperCase());
    }
    if (product.locationName && product.locationName === searchStr) product['searchIndex'] = 0;
    if (product.productId === matchedProductId) product['searchIndex'] = 0;
    return product;
}

export default function Inventory({permissions, shop, user}) {

    const [inventoryItems, setInventoryItems] = useState();
    const [categoryMap] = useCategoryMap();
    const [selectedTab, setSelectedTab] = useState('totalInventory');
    const [editingProduct, setEditingProduct] = useState(null);
    const [newInventoryItem, setNewInventoryItem] = useState(null);
    const [reload, setReload] = useState(false);
    const [quickTransfer, setQuickTransfer] = useState();
    const [selectedItems, setSelectedItems] = useState({});
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [_search, setSearch] = useState('');
    const search = _search || '';

    useEffect(() => {
        let inventoryUrl = INVENTORY_URL_MAP[selectedTab];
        setInventoryItems(null);
        setEditingProduct(null);
        setSelectedItems({})
        let cancelled = false;
        axios.get(inventoryUrl).then(resp => !cancelled && setInventoryItems(resp.data)).catch(err => {
            console.error(err);
            // toast.error('Inventory Database not responding');
        });
        return _ => cancelled = true;
    }, [selectedTab, reload]);

    const [matchedProductId, setMatchedProductId] = useState('');
    useEffect(_ => {
        setSearchInProgress(true);
        const timeoutId = setTimeout(_ => setSearchInProgress(false), 500)
        setMatchedProductId('');
        if (!_search) return;
        axios.get(`/sku/${_search.toUpperCase()}`).then(resp => {
            if (resp.data && resp.data.productId) setMatchedProductId(resp.data.productId);
        })
        return _ => timeoutId && clearTimeout(timeoutId)
    }, [_search])


    let searchResults = [];
    if (inventoryItems) searchResults = inventoryItems.map(p => generateSearchResult(p, search, matchedProductId))
        .filter(p => p.searchIndex >= 0) // && !currentInventory.filter(i => i).find(current => current.productId === p.productId))
        .sort((a, b) => a.searchIndex - b.searchIndex )
        //.map(product => <div className="inventory-search-result" key={product._id}> <InventoryItem product={product}/> </div>);


    searchResults.forEach(item => item.categoryName = categoryMap[item.category] && categoryMap[item.category].label)

    const InventoryItemButton = ({inventoryItem}) => {
        if (shop?.inventoryManagement === 'blaze' && shop?.allowCounterPickup) {
            const addPickupItem = _ => setQuickTransfer({productName: inventoryItem.name, sourceItem: inventoryItem, productId: inventoryItem.productId, fromLocationType: 'backstock', toLocationType: 'sellable'})
            const addMorePickupItem = _ => setQuickTransfer({productName: inventoryItem.name, destinationItem: inventoryItem, productId: inventoryItem.productId, fromLocationType: 'backstock', toLocationType: 'sellable'})
            const removePickupItem = _ => setQuickTransfer({productName: inventoryItem.name, sourceItem: inventoryItem, productId: inventoryItem.productId, fromLocationType: 'sellable', toLocationType: 'backstock'})
            if (selectedTab === 'backstock') return <Button block size='sm' className='py-0' onClick={addPickupItem}>Add Pickup</Button>
            if (selectedTab === 'pickup') {
                return <ButtonGroup>
                    <Button size='sm' className='py-0' onClick={addMorePickupItem}>Add</Button>
                    { (inventoryItem.stock > 0) && <Button size='sm' className='py-0' variant='danger' onClick={removePickupItem}>Remove</Button> }
                </ButtonGroup>
            }
        }
         return '';
    }

    const onClickItem = inventoryItem => {
        if (inventoryItem?._id
            && selectedTab === 'transfer'
            && !inventoryItem?.terminalId?.includes('-t')
        ) setSelectedItems({ ...selectedItems, [inventoryItem._id]: !selectedItems[inventoryItem._id] })
    }

    return (
        <>
        { permissions && (permissions.some(per => per === 'inventory') || permissions.some(per => per === 'admin')) ?
            <div>
                <div>
                    { shop?.inventoryManagement === 'greenstop' ?
                        <>
                            {editingProduct ? <EditProductModal productIn={editingProduct} hide={() => setEditingProduct(null)} afterSave={_ => setReload(!reload)} /> : null}
                            {newInventoryItem ? <AssignPackageModal packageToAssign={newInventoryItem} hide={() => setNewInventoryItem(null)} afterSave={_ => setReload(!reload)} /> : null}
                            <InventoryContainer>
                                <Button onClick={() => setNewInventoryItem({ Label: 'none' })}>Add Non-METRC item to inventory</Button>
                            </InventoryContainer>
                        </>
                        :
                        shop?.allowCounterPickup && quickTransfer && <QuickTransferModal user={user} transfer={quickTransfer} close={success => {
                            setQuickTransfer(null);
                            if (success) setReload(!reload);
                        }}/>
                    }
                    <Row className='mt-2 pt-0'>
                        <Col md='auto' className='pt-0'>
                            <Nav variant="tabs" defaultActiveKey='totalInventory' onSelect={tabKey => setSelectedTab(tabKey)}>
                                <Nav.Item>
                                    <Nav.Link className={selectedTab === 'totalInventory' ? 'bg-primary text-light' : 'border-0 text-primary'} eventKey='totalInventory'> All </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={selectedTab === 'kioskInventory' ? 'bg-primary text-light' : 'border-0 text-primary'} eventKey='kioskInventory'> Kiosk </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={selectedTab === 'pickup' ? 'bg-primary text-light' : 'border-0 text-primary'} eventKey='pickup'> Pickup </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={selectedTab === 'backstock' ? 'bg-primary text-light' : 'border-0 text-primary'} eventKey="backstock"> Back Stock </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col className='pt-0'>
                            <InventorySearch disableAutoFocus={!!quickTransfer} search={search} onSearch={setSearch}/>
                        </Col>
                    </Row>
                    <InventoryTable selectedItems={selectedItems} loading={!inventoryItems || searchInProgress} inventoryItems={searchResults}
                        setSelectedItems={['pickup', 'backstock'].some(k => selectedTab === k) && setSelectedItems}
                        setEditingProduct={p => setEditingProduct({ ...p, _id: p.productId })}
                        onClickItem={onClickItem}
                        ActionElement={InventoryItemButton} />
                </div>
            </div>
            :
            <h1 style={{marginTop: '30px',}}>You do not have access to the inventory</h1>
        }
        </>
    )
}
