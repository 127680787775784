import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Loading from '../loading';
import axios from 'axios';
import { useCallback, useState } from 'react';

const COUNTER_PICKUP_SETTINGS_URL = '/settings/allowCounterPickup';

export default function PickupCounterSettings({currentShopSettings, refreshCurrentShopSettings}) {
    const [disabled, setDisabled] = useState(false);

    const setAllowCounterPickup = useCallback(value => {
        setDisabled(true);
        console.log(value)
        axios.put(COUNTER_PICKUP_SETTINGS_URL, {allow: value}).catch().then(_ => {
            refreshCurrentShopSettings();
            setDisabled(false);
        });
    }, [refreshCurrentShopSettings])

    if (!currentShopSettings) return <Loading/>
    return <Container className='mt-3'>
        <Form.Check checked={currentShopSettings.allowCounterPickup || false} onChange={e => setAllowCounterPickup(e.target.checked)} label="Allow Counter Pickup" disabled={disabled} id='counter-pickup-switch' type='switch'/>
    </Container>
}