import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function expandTaxes(taxes) {
    if (!taxes) return false;
    taxes = Object.values(taxes)

    return taxes.map((tax, index) => (
        <Row key={tax.name} className='mt-2'>
            <Col className='pt-0 pl-1 text-left'>{tax.name}</Col>
            <Col className='pt-0 pr-4'>
                <p className='mb-0 pr-2 pull-right'>{(tax.amount).toFixed(2)}</p>
            </Col>
        </Row>
    ))
}

/**
* Renders the tip amount if any
* 
* @param {number} tipAmount 
* @returns the tip amount row
*/
function conditionalRenderTipAmount(tipAmount) {
    if (tipAmount) {
        return (
            <Row className='mt-2'>
                <Col className='pt-0 pl-1 text-left' >Tip</Col>
                <Col className='pt-0 pr-4'>
                    <p className='mb-0 pr-2 pull-right text-success'> + {(tipAmount).toFixed(2)}</p>
                </Col>
            </Row>
        )
    }
}


function conditionalRenderDiscount({ order }) {
    if (order.discount && order.discount !== 0) {
        return (
            <Row className='mt-2'>
                <Col className='pt-0 pl-1 text-left text-success' >{Object.keys(order.promoCodesApplied).join()}</Col>
                <Col className='pt-0 pr-4 font-weight-bold'>
                    <p className='mb-0 pr-2 pull-right text-success'> - {(order.discount).toFixed(2)}</p>
                </Col>
            </Row>
        )
    }
}

function conditionalRenderCardFee(cardFee) {
    if (cardFee) {
        return (
            <Row className='mt-2'>
                <Col className='pt-0 pl-1 text-left' >Card Fee</Col>
                <Col className='pt-0 pr-4'>
                    <p className='mb-0 pr-2 pull-right text-danger'> + {(cardFee).toFixed(2)}</p>
                </Col>
            </Row>
        )
    }
}


export default function OrderPricingSection({ order }) {

    return <>
        <Row className='mt-2'>
            <Col className='pt-0 pl-1 text-left' >Sub Total</Col>
            <Col className='pt-0 pr-4 font-weight-bold'>
                <p className='mb-0 pr-2 pull-right'>$ {(order.subTotal).toFixed(2)}</p>
            </Col>
        </Row>
        {conditionalRenderDiscount({ order })}
        {expandTaxes(order.taxes)}
        <Row className='mt-2 pt-2 pb-2' style={{ backgroundColor: "#f7f7f7" }}>
            <Col className='pt-0 pl-1 text-left'>TOTAL</Col>
            <Col className='pt-0 pr-4 font-weight-bold'>
                <p className='mb-0 pr-2 pull-right'>$ {(order.total).toFixed(2)}</p>
            </Col>
        </Row>
        {conditionalRenderCardFee(order.cardFee)}
        {conditionalRenderTipAmount(order.tipAmount)}
        <Row className='mt-2 pt-2 pb-2'>
            <Col className='pt-0 pl-1 text-left'>Paid ({order.paymentType})</Col>
            <Col className='pt-0 pr-4 font-weight-bold'>
                <p className='mb-0 pr-2 pull-right'>$ {(order.amountPaid).toFixed(2)}</p>
            </Col>
        </Row>
        {!!order.change && <Row className='mt-2 pt-2 pb-2'>
            <Col className='pt-0 pl-1 text-left'>Change ($ {order.change})</Col>
            <Col className='pt-0 pr-4'>
                <p className='mb-0 pr-2 pull-right'>
                    ${(order.changeDispensed).toFixed(2)}
                </p>
            </Col>
        </Row>}
    </>
}