import React, { useState } from 'react';
import ReactTable from 'react-table-6';
import Button from 'react-bootstrap/Button';
import AssignPackageModal from './modals/assignPackageModal';
import styled from 'styled-components';


export default function PackagesTable({ packages, refresh }) {

    const [packageToAssign, setPackageToAssign] = useState(null);

    let columns = [
        {
            Header: 'Greenstop Product Info',
            columns: [
                {
                    Header: 'ID',
                    id: 'assignID',
                    accessor: p => p,
                    Cell: p => p?.value?.productData ?
                        <Button block variant='secondary' onClick={() => setPackageToAssign(p.value)}>{p.value.productId}</Button> :
                        <Button block onClick={() => setPackageToAssign(p.value)}>Assign</Button>
                },
                {
                    Header: 'Name',
                    id: 'productName',
                    accessor: p => p,
                    Cell: p => p?.value?.productData?.name || '-'
                }
            ]
        },
        {
            Header: 'METRC Info',
            columns: [
                {
                    Header: 'Label',
                    accessor: 'Label'
                },
                {
                    Header: 'Package Type',
                    accessor: 'PackageType'
                },
                {
                    Header: 'Quantity',
                    id: 'Quantity',
                    accessor: row => row.Quantity + ' ' + row.UnitOfMeasureName
                },
                {
                    Header: 'Product ID',
                    accessor: 'ProductId'
                },
                {
                    Header: 'Product Name',
                    accessor: 'ProductName'
                },
                {
                    Header: 'Category',
                    accessor: 'ProductCategoryName'
                }
            ]
        }

    ]

    let modal = null;
    if (packageToAssign) {
        modal = <AssignPackageModal packageToAssign={packageToAssign} hide={() => refresh() || setPackageToAssign(null)} />;
    }

    return (
        <TableContainer>
            <ReactTable className="table" data={packages} columns={columns}             
            defaultPageSize= {7}
            pageSizeOptions= {[7, 10, 20]}
             />
            {modal}
        </TableContainer>
    )
}

const TableContainer = styled.div`
    height: calc(100vh - 133px);
    padding: 0;
    overflow: hidden;
    .table {
        max-height: 100%;
        min-height: 100%;
    }
    @media(max-width: 604px) {
        height: calc(100vh - 165px);
    }
`;
