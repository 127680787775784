import React, { useState, useEffect, useRef } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import styled from 'styled-components';
import { useHistory, useParams } from "react-router-dom";
import { DateTime } from 'luxon';
import Loading from './loading';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const ORDER_URL = '/order';
export default function HandleOrder(props) {

    const { orderId } = useParams();
    const history = useHistory();
    const [order, setOrder] = useState(null);
    const [action, setAction] = useState(null);
    const [money, setMoney] = useState(null);
    const [disableButtons, setDisableButtons] = useState(false);
    const wrapperRef = useRef(null);

    function useOnClickOutside(ref, handler) {
      useEffect(
        () => {
          const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) {
              return;
            }

            handler(event);
          };

          document.addEventListener('mousedown', listener);
          document.addEventListener('touchstart', listener);

          return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
          };
        },

        [ref, handler]
      );
    }

    useOnClickOutside(wrapperRef, () => setAction(null));

    useEffect(() => {
        const orderUrl = ORDER_URL + '/' + orderId;
        axios.get(orderUrl).then((response) => {
            setOrder(response.data);
        }).catch(console.error)
    }, [orderId]);

    const finishOrder = order => {
        axios.post(`/terminal/${order.terminalId}/order/finish`, order).then(resp => history.push("/openOrders"));
    }

    const cancelOrder = order => {
        axios.post(`/terminal/${order.terminalId}/order/cancel`, order).then(resp => history.push("/openOrders"));
    }

    const manuallyVend = (order, terminalId) => {
        if (terminalId && !order.sidesVended[terminalId]) {
            order.sidesVended[terminalId] = 'manual';
            order.items.filter(i => i.terminalId === terminalId).forEach(item => {
                for (let count = 0; count < item.quantity; count++) {
                    order.vendResults.push(
                        {
                            name: item.name,
                            price: item.price,
                            productId: item.productId,
                            _id: item._id,
                            inventoryItemId: item._id,
                            slot: item.slot,
                            slotLetter: String.fromCharCode(97 + item.slot),
                            success: true,
                            terminalId: item.terminalId,

                            manualVend: true
                        }
                    )
                }
            });
            order.awaitingPickup = Object.values(order.sidesVended).some(v => !v);
            order.terminalId = terminalId;
        } else if (order.vendResults && order.vendResults.length) {
            order.vendResults.forEach(result => {
                if (!result.success) result.manualVend = true;
                result.success = true;
            });
        } else {
            order.vendResults = [{
                success: true,
                manualVend: true
            }]
        }
        order.done = true;
        setDisableButtons(true);
        axios.post(`/terminal/${order.terminalId}/order/finish`, order).then(resp => {
            setDisableButtons(false);
            window.location.reload(false);
        });
    }

    const returnMoney = (e) => {
        e.preventDefault();
        const orderUrl = ORDER_URL + '/' + orderId + '/manage';
        axios.patch(orderUrl, {
            manuallyDispensed: {
                amount: parseFloat(money),
            },
        }).then(resp => history.push("/openOrders"));
    }

    const unlockOrder = _ => {
        const unlockUrl = '/terminal/management-dashboard/order/unlock/' + orderId;
        setDisableButtons(true)
        axios.post(unlockUrl).catch(console.error).then(_ => {
            setDisableButtons(false);
            window.location.reload();
        });
    }

    if (!order) return <Loading/>

    let change = order.done ? order.change : order.amountPaid;
    let textClass = (order.canceled && 'text-danger') || (order.done  && 'text-success') || 'text-warning';
    let orderStatus = (order.canceled && 'Canceled') || (order.done  && 'Vended') || 'In Progress';

    return (
        <>
            <Container style={{filter: !action ? 'blur(0)' : 'blur(1.5rem)'}}>
                <OrdersContainer>
                    <h1 className={textClass} style={{marginTop: '30px'}}>{orderStatus} - {DateTime.fromJSDate(new Date(order.updated)).toLocaleString(DateTime.DATETIME_SHORT)}</h1>
                    <Row className="orderInfo mt-0">
                        <Col>
                            <p className={textClass}>
                                <OverlayTrigger trigger={'click'} overlay={props => <Popover {...props} id="orderId-popover">_id: {order._id}</Popover>} placement="right">
                                    <span>#{order.transactionNumber || order._id} </span>
                                </OverlayTrigger>
                            </p>
                            <p>Customer: <span className="text-secondary">{order.customer.name}</span></p>
                            <p>DLN: <span className="text-secondary">{order.customer.driversLicenseNumber || order.customer.driversLiscenseNumber}</span></p>
                            <p>Terminal Id: <span className="text-secondary">{order.createdTerminalId ? `Side ${order.createdTerminalId.split('-t')[1]}` : 'Unknown'}</span></p>
                            <p>Created: {DateTime.fromJSDate(new Date(order.timestamp)).toLocaleString(DateTime.DATETIME_SHORT)}</p>
                        </Col>
                        <Col>
                            <ListGroup>
                                <ListGroup.Item className="d-flex justify-content-between">Paid ({order.paymentType}): <span className="font-italic text-secondary">${parseFloat(order.amountPaid).toFixed(2)}</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Change Owed: <span className="font-italic text-secondary">${parseFloat(change).toFixed(2)}</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Change Received: <span className="font-italic text-secondary">${parseFloat(order.changeDispensed).toFixed(2)}</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Change Needed: <span>${parseFloat(change - order.changeDispensed).toFixed(2)}</span></ListGroup.Item>
                                { order.blazeUpdateError &&
                                    <ListGroup.Item><div>Blaze Error: </div><span className="font-italic">{order.blazeUpdateError.message}</span></ListGroup.Item>
                                }
                                { order.treezUpdateError &&
                                    <ListGroup.Item><div>Treez Error: </div><span className="font-italic">{order.treezUpdateError.message || order.treezUpdateError.resultDetail}</span></ListGroup.Item>
                                }
                                <ListGroup.Item>
                                    { !order.canceled && order.blazeUpdateError && <Button disabled={disableButtons} block onClick={_ => finishOrder(order)}>Retry Complete In Blaze</Button> }
                                    { !order.canceled && order.treezUpdateError && <Button disabled={disableButtons} block onClick={_ => finishOrder(order)}>Retry Complete In Treez</Button> }
                                    { !order.done && !order.canceled && <Button disabled={disableButtons} variant="danger" block onClick={_ => cancelOrder(order)}>Mark as Canceled</Button> }
                                    { (order.vendResults ? order.vendResults.some(r => !r || !r.success) : (order.confirmed && !order.done)) &&
                                        <Button disabled={disableButtons} variant="warning" block onClick={_ => manuallyVend(order)}>Mark as Manually Vended</Button>
                                    }
                                    { order.done && order.change-order.changeDispensed >= 1 && <Button disabled={disableButtons} block onClick={() => setAction('manual')}>Manually Dispense Change</Button> }
                                    { order.canceled && !order.done && (order.amountPaid >= order.changeDispensed + 1) && <Button disabled={disableButtons} block onClick={() => setAction('manual')}>Refund Payment</Button> }
                                    { order.locked && <Button variant='outline-warning' block disabled={disableButtons} onClick={unlockOrder}>Unlock</Button>}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        { order.done && order.sidesVended && Object.keys(order.sidesVended).some(k => !order.sidesVended[k]) &&
                            <Col lg='auto' xl='6'>
                                <ListGroup className='mb-2'>
                                    <ListGroup.Item variant='secondary'>Not Vended:</ListGroup.Item>
                                    { Object.keys(order.sidesVended).filter(k => !order.sidesVended[k]).map((terminalId) =>
                                        <ListGroup.Item className='p-0'>
                                            <ListGroup.Item variant="warning" className=''>
                                                { terminalId === 'pos' ? 'Pickup Counter' :`Side ${terminalId.split('-t')[1]}`}
                                            </ListGroup.Item>
                                            {order.items.filter(i => i.terminalId === terminalId).map(item =>
                                                Array.from({length: item.quantity}, _ =>
                                                    <ListGroup.Item key={item._id} className='text-warning border-0'>
                                                        {item.name}
                                                    </ListGroup.Item>
                                                )
                                            )}
                                            <ListGroup.Item  className='p-1 border-0'>
                                                <Button disabled={disableButtons} variant='outline-warning' block onClick={_ => manuallyVend(order, terminalId)}>Manually Dispense </Button>
                                            </ListGroup.Item>
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            </Col>
                        }
                        <Col>
                            { (order.confirmed || order.done) && (order.vendResults ?
                                <ListGroup>
                                    <ListGroup.Item variant="secondary">Vend Results: </ListGroup.Item>
                                    { order.vendResults.map((result, idx) =>
                                        <ListGroup.Item key={idx} style={{color: result.success ? 'green' : 'red'}}>
                                            { result.name }
                                            { result.price ? <span> (${result.price}) </span> : null }
                                        </ListGroup.Item>
                                    )}
                                </ListGroup> :
                                <p style={{color: 'red'}}>Not Vended</p>)
                            }
                        </Col>
                    </Row>
                </OrdersContainer>
            </Container>
            { action === 'manual' ?
                <ManuallyDispensed ref={wrapperRef}>
                    <h1>Money Returned to Customer</h1> 
                    <form style={{textAlign: 'center'}} onSubmit={(e) => returnMoney(e)}>
                        <span>$</span> <input onChange={(e) => setMoney(e.target.value)} type="number" step="0.01" />
                        <button disabled={disableButtons} type="submit">Submit</button>
                    </form>
                </ManuallyDispensed>
            :
            null
            }
        </>
    )
}


const OrdersContainer = styled.div`
    margin: 10px;
    h1 { 
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .orderInfo {
        text-align: left;
        p {
            font-size: 24px;
        }

    }
    .orderActions {
        button { 
            padding: 10px 15px;
            border-radius: 5px;
            border: 0;
            background: #26A65B;
            color: #FFFFFF;
            font-size: 24px;
        }
    }
`;

const ManuallyDispensed = styled.div`
    padding: 20px 40px;
    position: fixed;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.6);
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    min-height: 300px;
    margin-top: -150px
    background-color: #FFFFFF;
    z-index: 9999999;
    background: #FFFFFF;
    border-radius: 5px;
    .confirmInfo {
        width: 90%;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        p {
            color: #26A65B;
            font-size: 40px;
            opacity: .8;
        }
        p:hover {
            cursor: pointer;
        }
    }
    h1 {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    form {
        span {
            display: inline-block;
        }
        input {
            display: inline-block;
            width: 80%;
            margin: 0 auto;
            border-radius: 5px;
            border: 1px solid #EFEFEF;
            margin-bottom: 40px;
        }
        button {
            display: block;
            border: 0;
            border-radius: 5px;
            background-color: #26A65B;
            color: #FFFFFF;
            margin: 0 auto;
            width: 200px;
            padding: 15px 20px;
            font-size: 20px;
        }
    }

`