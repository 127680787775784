import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';

const TAX_SETTINGS_URL = '/settings/taxes';

export default function TaxSettings({currentShopSettings, refreshCurrentShopSettings}) {


    const [disableSubmit, setDisableSubmit] = useState(false);


    const saveTaxSettings = () => {
        setDisableSubmit(true);
        axios.put(TAX_SETTINGS_URL, {})
            .then(_ => {
                toast.success('Tax Setting Saved!')
                refreshCurrentShopSettings()
            })
            .catch(e => {
                console.error(e)
                refreshCurrentShopSettings()
            })
            .finally(_ => setDisableSubmit(false));
    };

    return (
        <div>
            <h1>Update Taxes</h1>
            <Form>
                    <Form.Row>
                        
                    </Form.Row>
                <Button disabled={disableSubmit} variant="primary" onClick={saveTaxSettings}>Save changes</Button>
            </Form>
        </div>
    )
}
