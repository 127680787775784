import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Modal from "react-bootstrap/esm/Modal";
import Loading from "../loading";
import useBackstockLocations from "../../hooks/useBackstockLocations";

export default function QuickTransferModal({transfer, close, user}) {

    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [productInfo, setProductInfo] = useState();
    const [backstockLocations] = useBackstockLocations();
    const [sourceItem, setSourceItem] = useState(transfer.sourceItem);
    const [destinationItem, setDestinationItem] = useState(transfer.destinationItem);
    const toLocationType = transfer.toLocationType;
    const fromLocationType = transfer.fromLocationType;
    const [quantity, _setQuantity] = useState(1);
    const setQuantity = useCallback(quantity => quantity <= sourceItem?.stock ? _setQuantity(quantity) : _setQuantity(sourceItem?.stock || 1), [sourceItem?.stock])
console.log(user)
    useEffect(_ => {
        axios.get(`/product/${transfer.productId}`).then(resp => setProductInfo(resp.data))
    }, [transfer.productId])



    const submitTransfer = _ => {
        if (!(transfer?.productId &&  toLocationType && fromLocationType && quantity && sourceItem?.stock && sourceItem.stock >= quantity)) return;

        
        transfer = {
            productId: transfer.productId,
            quantity,
            toLocationType,
            fromLocationType,
            fromLocationId: sourceItem.locationId,
            toLocationId: destinationItem.locationId,
            toLocationName: destinationItem.locationName,
            startingStockTo: sourceItem.stock,
            startingStockFrom: destinationItem.stock,
            productName: transfer.productName,

            employeeId: user.blazeId || user.user_id,
        }
        console.log(transfer);

        setButtonsDisabled(true)
        axios.post('/inventory/transfer', transfer).then(_ => close(true)).finally(_ = setButtonsDisabled(false))
    }
    console.log(destinationItem)

    if (!backstockLocations || !productInfo) return <Modal show={true}><Modal.Body><Loading positionDefault={true}/></Modal.Body></Modal>

console.log(destinationItem)


    let fromInventoryOptions = {
        backstock: productInfo?.backstockInventory,
        sellable: productInfo?.sellableInventory
    }[fromLocationType]
    
    let toInventoryOptions = {
        backstock: backstockLocations.map(location => ({
            ...location,
            stock: (productInfo?.backstockInventory || []).find(item => item.locationId === location.locationId)?.stock ||  0
        })),
        sellable: [{
            stock: (productInfo?.sellableInventory || [])
                .find(item => item.locationId === productInfo?.shopId + '-pickup')?.stock || 0,
            locationId: productInfo?.shopId + '-pickup',
            locationName: 'Default Pickup Location'
        }]
    }[toLocationType]

    return <Modal show={true} onHide={_ => !buttonsDisabled && close()}>
        <Modal.Title className="pb-0 border-bottom">
            <h5 className="text-center pt-2 px-2 text-truncate">{transfer.productName}</h5>
            <h6 className="text-right px-2">{fromLocationType} {"->"} {toLocationType}</h6>
        </Modal.Title>
        <Modal.Body>
            <Form.Group>
                <Form.Label>From:</Form.Label>
                <Form.Control as="select" value={sourceItem?.locationId || ''} onChange={e => setSourceItem(fromInventoryOptions.find(item => item.locationId === e.target.value))}>
                    {!sourceItem && <option key="" value={null}>---</option>}
                    { fromInventoryOptions.map(inventoryItem => console.log(inventoryItem) ||
                        <option key={inventoryItem?.locationId} value={inventoryItem?.locationId}>
                            {`${inventoryItem?.locationName} (${inventoryItem?.stock})` }
                        </option>
                    )}
                </Form.Control>
            </Form.Group>
            { sourceItem && <>
                <Form.Group>
                    <Form.Label>To:</Form.Label>
                    <Form.Control as="select" defaultValue={destinationItem?.locationId || null} onChange={e => setDestinationItem(toInventoryOptions.find(item => item.locationId === e.target.value))}>
                        {!destinationItem && <option key="" value={null}>---</option>}
                        { toInventoryOptions.map(toOption =>
                            <option key={toOption?.locationId} value={toOption?.locationId}>
                                {`${toOption?.locationName} (${toOption?.stock || 0})`} 
                            </option>
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Quantity (max: {sourceItem.stock})</Form.Label>
                    <Form.Control min={1} max={sourceItem.stock} type="number" placeholder={quantity} value={quantity?.toString() || '0'} onChange={e => setQuantity(+e.target.value)} onFocus={e => e.target.value = ""} onBlur={e => e.target.value = quantity?.toString() || '0'}/>
                </Form.Group>
            </> }
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={buttonsDisabled} onClick={submitTransfer}>Submit</Button>
        </Modal.Footer>
    </Modal>
}