import ReactTable from 'react-table-6';
import styled from 'styled-components';
import Loading from '../loading';

export default function InventoryTable({ inventoryItems, loading, selectedItems, onClickItem, ActionElement }) {

    const columns = [
        {
            accessor: 'productId',
            Cell: "",
            PivotValue: "",
            width: 30
            
        },
        {pivot: true},
        {
            Header: _ => <div>Product Name</div>,
            accessor: 'name',
            aggregate: vals => vals[0],
            Cell: content => {
                if (content.aggregated) return <div style={{ textAlign: "left" }}>{content.value}</div>;
                if (ActionElement) return <ActionElement inventoryItem={content.original}/>;
                return ""
            },
            width: 300
        },
        {
            Header: 'Category',
            id: 'category',
            accessor: i => i.categoryName || i.category,
            aggregate: vals => vals[0],
            show: false
        },
        {
            Header: 'Weight',
            id: 'weight',
            accessor: p => (p.amount || '') + ' ' + (p.unit || ''),
            aggregate: vals => vals[0],
            show: false
        },
        {
            Header: 'SKU',
            accessor: 'sku',
            aggregate: vals => vals[0]
        },
        {
            Header: 'Price',
            accessor: 'price',
            aggregate: vals => vals[0],
            width: 100
        },
        {
            Header: 'Stock',
            accessor: 'stock',
            aggregate: vals => vals.reduce((totalStock, stock) => totalStock + stock, 0),
            Cell: content => <div className={content.value < 0 || content.subRows?.reduce((o, v) => o || v.stock < 0 ,false) ? 'bg-danger' : ''}>{content.value}</div>,
            width: 75
        },
        {
            Header: 'Location',
            id: 'location',
            width: 300,
            accessor: i => {
                let location = i.location || i.locationName ||  i.locationId || 'Unknown';
                return location.split('-t')[1] ? `Side ${location.split('-t')[1]}` : location;
            }
        }
    ];

    return (
        <TableContainer>
            <ReactTable pivotBy={['productId']}
                PivotValueComponent={_ => ''}
                data={inventoryItems || []}
                LoadingComponent={loading ? Loading : _ => null}
                getLoadingProps={_ => ({noDelay: true})}
                columns={columns}
                className='-striped -highlight table'
                defaultPageSize= {25}
                showPageSizeOptions={false}
                freezeWhenExpanded={!loading}
                getTrProps={(state, rowInfo) => 
                    rowInfo?.row ?  {
                        onClick: _ => onClickItem(rowInfo.original),
                        style: {
                            color: !rowInfo.aggregated && selectedItems[rowInfo.original?._id] ? 'white' : '',
                            background: !rowInfo.aggregated && selectedItems[rowInfo.original?._id] ? '#26A65B' : ''
                        }
                    } : {}
                }
                />
        </TableContainer>
    )
}

const TableContainer = styled.div`
    height: calc(100vh - 200px);
    padding: 0;
    overflow: hidden;
    .table {
        max-height: 100%;
        min-height: 100%;
    }
    @media(max-width: 928px) {
        height: calc(100vh - 180px);
    }
    @media(max-width: 643px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 538px) {
        height: calc(100vh - 250px);
    }
    @media(max-width: 528px) {
        height: calc(100vh - 300px);
    }
    @media(max-width: 500px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 364px) {
        height: calc(100vh - 270px);
    }
`;
