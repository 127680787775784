import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useProducts from '../../hooks/useProducts';
import axios from 'axios';
import EditProductModal from './editProductModal';
import useBackstockLocations from '../../hooks/useBackstockLocations';
import styled from 'styled-components';

const BACKSTOCK_URL = '/backstock/';


export default function AssignPackageModal({ packageToAssign, hide, afterSave }) {

    const [products, reloadProducts] = useProducts('all');

    const [locations] = useBackstockLocations('all');

    const [done, setDone] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [sku, setSku] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState(null);

    // will remain null until product is selected from dropdown
    const [selectedProduct, _setSelectedProduct] = useState(null);
    const setSelectedProduct = product => {
        _setSelectedProduct(product);
        product && packageToAssign.Quantity ?
            setSelectedQuantity(Math.floor(packageToAssign.Quantity / product.amount)) :
            setSelectedQuantity(null);
    }

    const [productToCreate, setProductToCreate] = useState(null);

    const createProductFromPackage = useCallback(() => {
        setProductToCreate({
            name: packageToAssign.ProductName,
            amount: 1,
            price: undefined,
            description: '',
            unit: (packageToAssign.UnitOfMeasureName || '').toLowerCase()
        });
    }, [setProductToCreate, packageToAssign])


    const afterProductSave = product => {
        reloadProducts();
        setSelectedProduct(product);
        setProductToCreate(null);
    }

    const createBackstockEntry = useCallback(() => {
        setDisabled(true);
        axios.put(BACKSTOCK_URL + selectedLocation, {
            locationId: selectedLocation,
            productId: selectedProduct._id,
            metrcId: packageToAssign.Label,
            update: !!packageToAssign.productId,
            stock: selectedQuantity,
            waste: packageToAssign.Quantity % selectedProduct.amount,
            sku: sku
        }).then(resp => {
            afterSave();
            hide();
        }).catch(error => error);
    }, [selectedProduct, selectedLocation, packageToAssign, selectedQuantity, hide, afterSave, sku])


    let assignToProduct = (
        <ModalView>
            Assign to existing product:
            <select onChange={event => setSelectedProduct(products[event.target.value])}>
                <option key="default" value={null}>---</option>
                {products.map((product, index) => <option key={product._id} value={index}>{product.name}({product.amount} {product.unit})</option>)}
            </select>
            <button onClick={createProductFromPackage}>
                Create New Product
            </button>
        </ModalView>
    );


    let confirmInfo = selectedProduct ? (
        (selectedQuantity && selectedLocation && sku.length >= 6 && done) ?
            <div>
                Are you sure you would like to assign {' '}
                {packageToAssign.ProductName ?
                    <span className="badge badge-secondary">
                        {packageToAssign.ProductName} (
                        <span className="font-weight-bold">{packageToAssign.Quantity} </span>
                        {packageToAssign.UnitOfMeasureName})
                    </span> :
                    <span className="badge badge-secondary">Non METRC Item</span>
                }
                {' to '}
                <span className="badge badge-primary">
                    {selectedProduct.name} (
                    <span className="font-weight-bold">{selectedQuantity} </span>
                    X {selectedProduct.amount} {selectedProduct.unit})
                </span>
            </div> :
            <SureContainer>
                { packageToAssign.ProductName || 'Non Metrc Item'} =&gt;
                <span className="badge badge-primary">
                    {selectedProduct.name} (
                        {selectedQuantity || <input type='number' onBlur={e => setSelectedQuantity(parseInt(e.target.value))}></input>} X {selectedProduct.amount} {selectedProduct.unit}
                    )
                </span>
                <div className="selectContainer">
                    Location:
                    <select onChange={event => setSelectedLocation(locations[event.target.value] ? locations[event.target.value].key : null)}>
                        <option key="default" value={null}></option>
                        {locations.map((location, index) => <option key={location._id} value={index}>{location.locationName}</option>)}
                    </select>
                </div>
                <div className="skuContainer">
                    SKU:
                    <input value={sku} onChange={e => setSku(e.target.value)}></input>
                </div>
            </SureContainer>
    ) : null;


    let modal = null;
    if (productToCreate) modal = <EditProductModal productIn={productToCreate} hide={() => setProductToCreate(null)} afterSave={afterProductSave} />
    else modal = (
        <Modal show={true} className='assignModal' onHide={hide}>
            <ModalContainer>
                <Modal.Header closeButton>
                    <Modal.Title><span className="font-weight-bold">{packageToAssign.ProductName}</span> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedProduct ? confirmInfo : assignToProduct}
                </Modal.Body>
                {
                    selectedProduct && selectedLocation && selectedQuantity && done ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setSelectedProduct(null)}>No</Button>
                            <Button variant="primary" disabled={disabled} onClick={() => createBackstockEntry()}>Yes</Button>
                        </Modal.Footer> :
                        <Modal.Footer> {selectedProduct ? <Button onClick={_ => setDone(true)}>Next</Button> : null} </Modal.Footer>
                }
            </ModalContainer>
        </Modal>
    );
    return modal;
}

const ModalView = styled.div`
    select {
        width: 54%;
        background-color: #FFFFFF;
        height: 40px;
        margin-left: 2%;
        margin-bottom: 4%;
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    button {
        margin-right: 0;
        margin-left: auto;
        display:block;
        background-color: #26A65B;
        border-radius: 5px;
        height: 40px;
        color: #FFFFFF;
        border: 0;
        padding: 0 10px;
    }
`;

const ModalContainer = styled.div`
    .modal-header {
        border-bottom: 0;
    }
`;

const SureContainer = styled.div`
    .badge {
        margin-bottom: 15px;
        margin-top: 15px;
        display: block;
    }
    .skuContainer {
        display: flex;
        justify-content: space-between;
        input {
            border: 1px solid lightgrey;
            border-radius: 5px;
        }
    }
    .selectContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        select {
            width: 100px;
            border: 1px solid lightgrey;
            border-radius: 5px;
        }
    }

`;
