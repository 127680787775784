import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import styled from 'styled-components';
import {  toast } from 'react-toastify';

const EMPLOYEE_URL = '/employees';
const PERMISSION_URL = '/permissions';

const PERMISSIONS = [
    { permission: "Add/Edit/Delete Products", permissionKey: "products" },
    { permission: "See Inventory", permissionKey: "inventory", blaze: true, teez: true },
    { permission: "See Packages", permissionKey: "packages" },
    { permission: "See Customers", permissionKey: "customers", blaze: true, treez: true },
    { permission: "See Sales", permissionKey: "sales", blaze: true, treez: true },
    { permission: "See Kiosks", permissionKey: "kiosks", blaze: true, treez: true },
    { permission: "See Invoices", permissionKey: "invoices", blaze: true, treez: true },
    { permission: "Admin", permissionKey: "admin", blaze: true, treez: true },
];

const BLAZE_PERMISSIONS = PERMISSIONS.filter(p => p.blaze);
const TREEZ_PERMISSIONS = PERMISSIONS.filter(p => p.treez);

export default function EditEmployeeModal({ afterSave, hide, employeeIn, shop }) {
    const [employee, setEmployee] = useState(employeeIn);
    const [permissions, setPermissions] = useState({});
    const [ogPermissions, setOGPermissions] = useState({});
    const [disabled, setDisabled] = useState(true);

    const [availablePermissions, setAvailablePermissions] = useState(null);

    useEffect(_ => {
        if (!shop) setAvailablePermissions(null);
        else if (shop.inventoryManagement === 'blaze') setAvailablePermissions(BLAZE_PERMISSIONS);
        else if (shop.inventoryManagement === 'treez') setAvailablePermissions(TREEZ_PERMISSIONS);
        else setAvailablePermissions(PERMISSIONS);
    }, [shop])

    useEffect(() => {
        if (!availablePermissions) return;
        if(employeeIn.user_id) {
            setDisabled(false)
            axios.get(PERMISSION_URL + '/' + employeeIn.user_id).then(({ data }) => {
                let permissionsObj ={}
                data.map(i => i.permission_name).forEach(permissionKey => permissionsObj[permissionKey] = true)
                setPermissions(permissionsObj)
                setOGPermissions(permissionsObj)
            }).catch(console.error);
        }
    }, [employeeIn.user_id, availablePermissions]);

    useEffect(() => {
        if(shop.inventoryManagement === 'blaze' && employee?.user_id && !employee.blazeIdSet) {
            setDisabled(true)
            axios.get(EMPLOYEE_URL + '/' + employee.user_id).then(({ data }) => {
                if (data?.app_metadata?.blazeId) setEmployee({...employee, blazeId: data.app_metadata.blazeId, blazeIdSet: true})
                else setEmployee({...employee, blazeIdSet: 'no blaze id'})
            }).catch(e => setEmployee({...employee, blazeIdSet: 'failed'})).finally(_ => setDisabled(false));
        }
    }, [employee, shop]);

    let updateEmployee = (key, value) => {
        let newEmployee = { ...employee };
        newEmployee[key] = value;
        setEmployee(newEmployee);
        if(newEmployee['email'] && newEmployee['name']) setDisabled(false);
        else setDisabled(true);
    }

    const updatePermissions = (key, value) => {
        let newPermissions = { ...permissions };
        newPermissions[key] = value;
        setPermissions(newPermissions)
    }

    const selectAllPermissions = _ => {
        let newPermissions = availablePermissions.reduce((obj, p) => {
            obj[p.permissionKey] = true;
            return obj
        }, {})
        setPermissions(newPermissions)
    }

    const deselectAllPermissions = _ => setPermissions({});

    const saveEmployee = (e) => {
        setDisabled(true)
        e.preventDefault();
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validateEmail = re.test(employee['email'].toLowerCase());
        if (validateEmail && employee['name']) {
            let addPermissions = [];
            let deletePermissions = [];
            let allPermissions = [];
            availablePermissions.forEach(item => {
                let p = item.permissionKey;
                if (permissions[p]) {
                    allPermissions.push(p);
                    if (!ogPermissions[p]) addPermissions.push(p);
                } else if (ogPermissions[p]) deletePermissions.push(p);
            })
            let method = employeeIn.user_id ? 'put' : 'post';
            axios[method](EMPLOYEE_URL, { ...employee, addPermissions, deletePermissions, permissions: allPermissions })
                .then(({ data }) => {
                    afterSave(data);
                    toast.success('Successfully Saved Employee')
                })
                .catch(error => {
                    setDisabled(false)
                    console.error(error)
                });
        } else {
            if (!validateEmail) toast.error('Email is not valid.');
            if (!employee.name) toast.error('Please fill out name.');
            setDisabled(false);
        }
    };

    return (
        <Modal show={!!employee} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>{employee.user_id ? 'Edit' : 'Create'} Employee:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => saveEmployee(e)}>
                    <Form.Group controlId="name">
                        <Form.Label>Employee Name</Form.Label>
                        <Form.Control type="text" placeholder="Employee Name" defaultValue={employeeIn.name} onChange={e => {updateEmployee(e.target.id, e.target.value);}} />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" placeholder="Email" defaultValue={employeeIn.email} onChange={e => {updateEmployee(e.target.id, e.target.value);}} />
                    </Form.Group>
                    {shop?.inventoryManagement === 'blaze' && <Form.Group controlId='blazeId'>
                        <Form.Label>Blaze "Internal ID"</Form.Label>
                        <Form.Control type="text" placeholder="Blaze ID" defaultValue={employee.blazeId} onChange={e => {updateEmployee(e.target.id, e.target.value);}} />
                    </Form.Group>}
                    <SelectContainer>
                        <h2>Employee Permissions</h2>
                        <span onClick={() => selectAllPermissions()}>SELECT ALL</span><span style={{color: "#000000",}}>/</span><span onClick={() => deselectAllPermissions()}>DESELECT ALL</span>
                    </SelectContainer>
                    { availablePermissions &&
                        <Form.Group className="permissions" controlId="permissions">
                            { availablePermissions.map(item => {
                                let key = item.permissionKey
                                return (
                                    <Form.Check type='radio' inline key={key} id={key}>
                                        <Form.Check.Input disabled={permissions['admin'] && key !== 'admin'} checked={permissions[key] || permissions['admin'] || false} type='checkbox' isValid onChange={e => updatePermissions(key, e.target.checked)}/>
                                        <Form.Check.Label>{item.permission}</Form.Check.Label>
                                    </Form.Check>
                                )
                            })}
                        </Form.Group>
                    }

                    <Modal.Footer>
                        <Button variant="secondary" onClick={hide}>Cancel</Button>
                        <Button disabled={disabled} variant="primary" type="submit">Save changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

const SelectContainer = styled.div`
    text-align: left;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 10px;
    h2 {
        width: 100%;
        display: inline-block;
        font-size: 24px;
    }
    span {
        color: #26A65B;
        margin-right: 10px;
        font-weight: bold;
    }
    span:hover{
        cursor: pointer;
    }
}
`;
